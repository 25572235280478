import { FaceRotationAngles } from "../../constants";
import _ from "lodash";

const MeshKeyPoints = [
  { index: 4, name: "noseTip" },
  { index: 359, name: "leftEyeOut" },
  { index: 362, name: "leftEyeIn" },
  { index: 130, name: "rightEyeOut" },
  { index: 133, name: "rightEyeIn" },
];

const drawDashedCurve = (ctx, step, noseYPoint, isOverlapped) => {
  const controlPoint = {
    x:
      2 * Math.round(ctx.canvas.width * FaceRotationAngles[step].x) -
      ctx.canvas.width / 2,
  };

  const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
  if (isOverlapped) {
    gradient.addColorStop(0, "rgba(142, 218, 141, 0)"); // hex-code #8eda8d same as mask-green
    gradient.addColorStop(0.2, "rgba(142, 218, 141, 0.5)");
    gradient.addColorStop(0.8, "rgba(142, 218, 141, 0.5)");
    gradient.addColorStop(1, "rgba(142, 218, 141, 0)");
  } else {
    gradient.addColorStop(0, "rgba(255,255,255,0)");
    gradient.addColorStop(0.2, "rgba(255,255,255,0.5)");
    gradient.addColorStop(0.8, "rgba(255,255,255,0.5)");
    gradient.addColorStop(1, "rgba(255,255,255,0)");
  }

  ctx.lineWidth = 3;
  ctx.fillStyle = gradient;
  ctx.shadowBlur = 0;
  ctx.beginPath();
  ctx.moveTo(ctx.canvas.width / 2, 0);
  ctx.quadraticCurveTo(
    controlPoint.x + 16,
    noseYPoint,
    ctx.canvas.width / 2,
    ctx.canvas.height,
  );

  ctx.quadraticCurveTo(
    controlPoint.x - 16,
    noseYPoint,
    ctx.canvas.width / 2,
    0,
  );

  ctx.closePath();

  ctx.fill();
};

const drawSolidCurve = (ctx, noseTip) => {
  const controlPoint = {
    x: 2 * noseTip.x - ctx.canvas.width / 2,
    y: 2 * noseTip.y - ctx.canvas.height / 2,
  };

  const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
  gradient.addColorStop(0, "rgba(255,255,255,0)");
  gradient.addColorStop(0.2, "rgba(255,255,255,1)");
  gradient.addColorStop(0.8, "rgba(255,255,255,1)");
  gradient.addColorStop(1, "rgba(255,255,255,0)");

  ctx.lineWidth = 2;
  ctx.strokeStyle = gradient;
  ctx.shadowBlur = 5;
  ctx.shadowColor = "rgba(255, 255, 255, 0.7)";
  ctx.beginPath();
  ctx.setLineDash([]);
  ctx.moveTo(ctx.canvas.width / 2, 0);
  ctx.quadraticCurveTo(
    controlPoint.x,
    controlPoint.y,
    ctx.canvas.width / 2,
    ctx.canvas.height,
  );
  ctx.stroke();
};

export const drawDetection = (face, ctx) => {
  if (face === null) return;

  const bbox = face.box;
  const keypoints = face.keypoints;

  // Draw Bounding box
  ctx.strokeStyle = "red";
  ctx.beginPath();
  ctx.rect(bbox.xMin, bbox.yMin, bbox.width, bbox.height);
  ctx.stroke();

  // Draw Face Landmark
  for (let i = 0; i < keypoints.length; i++) {
    ctx.beginPath();
    ctx.arc(keypoints[i].x, keypoints[i].y, 5, 0, 2 * Math.PI);
    ctx.fillStyle = "blue";
    ctx.fill();
  }
};

export const videoToCoverCanvasCoordinates = (
  faces,
  canvasWidth,
  canvasHeight,
  videoWidth,
  videoHeight,
  scaledWidth,
  scaledHeight,
  offsetX,
  offsetY,
) => {
  if (faces.length < 1) return null;

  const face = _.cloneDeep(faces[0]);

  face.box.xMin =
    Math.round((face.box.xMin / videoWidth) * scaledWidth) - offsetX;
  face.box.yMin =
    Math.round((face.box.yMin / videoHeight) * scaledHeight) - offsetY;
  face.box.xMax =
    Math.round((face.box.xMax / videoWidth) * scaledWidth) - offsetX;
  face.box.yMax =
    Math.round((face.box.yMax / videoHeight) * scaledHeight) - offsetY;

  face.box.xMin = Math.max(face.box.xMin, 0);
  face.box.yMin = Math.max(face.box.yMin, 0);
  face.box.xMax = Math.min(face.box.xMax, canvasWidth);
  face.box.yMax = Math.min(face.box.yMax, canvasHeight);

  if (
    face.box.xMin >= canvasWidth ||
    face.box.yMin >= canvasHeight ||
    face.box.xMax <= 0 ||
    face.box.yMax <= 0
  )
    return null;

  face.box.width = face.box.xMax - face.box.xMin;
  face.box.height = face.box.yMax - face.box.yMin;
  face.keypoints = [];

  // Filter Keypoints needed for face orientation and distance calculation
  for (const meshPoint of MeshKeyPoints) {
    const keypoint = faces[0].keypoints[meshPoint.index];
    face.keypoints.push({
      x: Math.round((keypoint.x / videoWidth) * scaledWidth) - offsetX,
      y: Math.round((keypoint.y / videoHeight) * scaledHeight) - offsetY,
      name: meshPoint.name,
    });
  }

  return face;
};

const calculateOverlapPercentage = (box1, box2) => {
  const xOverlap = Math.max(
    0,
    Math.min(box1.x + box1.width, box2.x + box2.width) -
      Math.max(box1.x, box2.x),
  );
  const yOverlap = Math.max(
    0,
    Math.min(box1.y + box1.height, box2.y + box2.height) -
      Math.max(box1.y, box2.y),
  );

  const intersectionArea = xOverlap * yOverlap;

  const totalArea1 = box1.width * box1.height;
  const totalArea2 = box2.width * box2.height;

  const overlapPercentage1 = (intersectionArea / totalArea1) * 100;
  const overlapPercentage2 = (intersectionArea / totalArea2) * 100;

  const overlapPercentage = Math.min(overlapPercentage1, overlapPercentage2);

  return overlapPercentage;
};

export const checkFrontFace = (
  face,
  alignCheck,
  maskXMin,
  maskYMin,
  maskWidth,
  maskHeight,
) => {
  const box1 = {
    x: face.box.xMin,
    y: face.box.yMin,
    width: face.box.width,
    height: face.box.height,
  };
  const box2 = {
    x: maskXMin,
    y: maskYMin,
    width: maskWidth,
    height: maskHeight,
  };
  const overlapPercentage = calculateOverlapPercentage(box1, box2);

  if (!alignCheck) return overlapPercentage > 40;

  const [eyeLeftPoint, eyeRightPoint, nosePoint] = getEyesNose(face.keypoints);
  const eyesAligned = isEyesAligned(
    eyeLeftPoint,
    eyeRightPoint,
    { x: maskXMin, y: maskYMin + maskHeight / 2 - 15 },
    15,
  ); // Tolerance 15 pixels
  const noseAligned = isNoseAligned(
    nosePoint,
    { x: maskXMin + maskWidth / 2, y: maskHeight },
    15,
  ); // Tolerance 15 pixels
  return overlapPercentage > 40 && eyesAligned && noseAligned;
};

const isEyesAligned = (
  eyeLeftPoint,
  eyeRightPoint,
  regionOrigin,
  tolerance,
) => {
  const lowerLimit = regionOrigin.y - tolerance;
  const upperLimit = regionOrigin.y + tolerance;

  return (
    eyeLeftPoint.y >= lowerLimit &&
    eyeRightPoint.y >= lowerLimit &&
    eyeLeftPoint.y <= upperLimit &&
    eyeRightPoint.y <= upperLimit
  );
};

const isNoseAligned = (nosePoint, regionOrigin, tolerance) => {
  const lowerLimit = regionOrigin.x - tolerance;
  const upperLimit = regionOrigin.x + tolerance;

  return nosePoint.x >= lowerLimit && nosePoint.x <= upperLimit;
};

const getEyesNose = (keypoints) => {
  let eyeRight = { x: 0, y: 0 },
    eyeLeft = { x: 0, y: 0 },
    nosePoint = { x: 0, y: 0 };

  for (let i = 0; i < keypoints.length; i++) {
    if (keypoints[i].name.includes("rightEye")) {
      eyeRight.x += keypoints[i].x;
      eyeRight.y += keypoints[i].y;
    } else if (keypoints[i].name.includes("leftEye")) {
      eyeLeft.x += keypoints[i].x;
      eyeLeft.y += keypoints[i].y;
    } else if (keypoints[i].name === "noseTip")
      nosePoint = { x: keypoints[i].x, y: keypoints[i].y };
  }

  // Get eyes center points
  eyeLeft.x = eyeLeft.x / 2;
  eyeLeft.y = eyeLeft.y / 2;

  eyeRight.x = eyeRight.x / 2;
  eyeRight.y = eyeRight.y / 2;

  return [eyeLeft, eyeRight, nosePoint];
};

export const checkFaceRotation = (step, noseYPoint, face, ctx) => {
  const index = face.keypoints.findIndex((item) => item.name === "noseTip");

  const currentNosePoint = face.keypoints[index];

  drawSolidCurve(ctx, currentNosePoint);

  const isCenterXSame = approximatelyEqual(
    currentNosePoint.x,
    Math.round(ctx.canvas.width * FaceRotationAngles[step].x),
    12,
  );

  drawDashedCurve(ctx, step, noseYPoint, isCenterXSame);
  return isCenterXSame;
};

const approximatelyEqual = (a, b, tolerance) => Math.abs(a - b) <= tolerance;
