import React, { createContext, useState, useContext, useCallback } from "react";

const UIDContext = createContext();

// Export the hook to access the UID context
export const useUID = () => {
  return useContext(UIDContext);
};

// Provider component to wrap around the app
export const UIDProvider = ({ children }) => {
  const [uid, setUid] = useState(null);

  // Function to update the UID manually
  const updateUID = useCallback((newUID) => {
    setUid(newUID);
  }, []);

  return (
    <UIDContext.Provider value={{ uid, updateUID }}>
      {children}
    </UIDContext.Provider>
  );
};
