import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import "./styles.css";

export const HeadInitialize = ({ onSkipClick, onContinueClick }) => {
  const { t } = useTranslation("scanModule");

  return (
    <div className="head-initialize">
      <div className="head-initialize-text">
        <h2 className="head-initialize-heading">{t("hairdresser-title")}</h2>
        <p>{t("hairdresser-description")}</p>
      </div>
      <div className="head-initialize-button-top">
        <Button
          text={t("continue")}
          onClick={() => onContinueClick()}
          alternateColor={true}
        />
      </div>
      <div className="head-initialize-button-bottom">
        <Button
          text={t("skip")}
          onClick={() => onSkipClick()}
          alternateColor={true}
          usePrimaryColor={false}
        />
      </div>
    </div>
  );
};
