import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import "./styles.css";

export const FormOptions = ({
  options,
  multiAllowed,
  selectedOptions,
  onSelect,
}) => {
  const { t } = useTranslation("customQuestions");

  return (
    <div className="form-options">
      {options.map((option, index) => (
        <Button
          key={index}
          text={t(option)}
          onClick={() => onSelect(option)}
          alternateColor={
            multiAllowed
              ? !selectedOptions.includes(option)
              : option !== selectedOptions
          }
          isRounded={true}
          usePrimaryColor={
            multiAllowed
              ? selectedOptions.includes(option)
              : option === selectedOptions
          }
          disabled={false}
        />
      ))}
    </div>
  );
};
