import * as amplitude from "@amplitude/analytics-browser";
import flowConfig from "../config/flowConfig.json";

// Initialize Amplitude

/*
- We don't need amplitudeInstance for now
*/
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
  defaultTracking: true,
  autocapture: {
    attribution: true,
    pageViews: true,
    sessions: true,
    formInteractions: false,
    fileDownloads: false,
    elementInteractions: true,
  },
  logLevel: amplitude.Types.LogLevel.Error,
});

// Event tracking utility
export const trackEvent = (eventName, eventProperties = {}) => {
  if (process.env.REACT_APP_AMPLITUDE_API_KEY !== "") {
    amplitude.track(eventName, eventProperties);
  }
};

export const trackPageEvent = (
  name,
  uid = "",
  lastPageName = "",
  email = "",
  productTitle = "",
  productUrl = "",
) => {
  const eventProperties = {
    partnerName: flowConfig.partnerName,
  };

  // check if UID is there
  if (uid) {
    eventProperties.uid = uid;
  }

  if (email) {
    eventProperties.email = email;
  }

  // check for product click event
  if (productTitle) {
    eventProperties.productTitle = productTitle;
    eventProperties.productUrl = productUrl;
  }

  // Track another event based on the last Page
  if (lastPageName) {
    // Track page visit event
    trackEvent(name, eventProperties);

    // Calculate the last Page & pass the event
    if (flowConfig.recommendationEnabled && lastPageName === "products") {
      eventProperties.pageName = lastPageName;
      trackEvent("webapp_user_visited_last_page", eventProperties);
    } else if (
      !flowConfig.recommendationEnabled &&
      lastPageName === "results"
    ) {
      eventProperties.pageName = lastPageName;
      trackEvent("webapp_user_visited_last_page", eventProperties);
    } else {
      return;
    }
  } else {
    trackEvent(name, eventProperties);
  }
};
