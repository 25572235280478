import { useState, useEffect, useRef } from "react";
import flowConfig from "../../config/flowConfig.json";
import { useTranslation } from "react-i18next";
import { languageOptions } from "./Mapping";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./styles.css";

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef();

  const updateLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setIsOpen(false);
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!btnRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <div ref={btnRef} className="language-dropdown">
      <li className={`language-item selected ${isOpen ? "hidden" : ""}`}>
        <span
          className={`fi fi-${languageOptions[i18n.language]} fis flag-circle`}
          onClick={() => setIsOpen((prevValue) => !prevValue)}
        ></span>
      </li>
      {isOpen && (
        <ul className="language-menu">
          {flowConfig.availableLanguages.map((lng, index) => (
            <li
              key={index}
              className="language-item"
              onClick={() => updateLanguage(lng)}
            >
              <span
                className={`fi fi-${languageOptions[lng]} fis flag-circle`}
              ></span>
              {lng}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
