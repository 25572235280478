export const NoGlassesIcon = ({ className }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        d="M14 17C14 17 15.1588 16.5 16 16.5C16.8412 16.5 18 17 18 17M1 15V17.7109C1 20.7336 3.2477 23.3332 6.26732 23.4692C6.69804 23.4886 7.11511 23.5 7.5 23.5C8.40046 23.5 9.47708 23.4375 10.4926 23.3566C12.5014 23.1967 14 21.4891 14 19.474V15C14 14.4477 13.5523 14 13 14H2C1.44772 14 1 14.4477 1 15ZM1 15L3 7M31 17V15M31 15C31 14.4477 30.5523 14 30 14H19C18.4477 14 18 14.4477 18 15V20.0745C18 20.5732 18.1057 21.0577 18.3018 21.5M31 15L33 7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9194 29.4194C28.4786 31.8602 24.5214 31.8602 22.0806 29.4194C19.6398 26.9786 19.6398 23.0214 22.0806 20.5806C24.5214 18.1398 28.4786 18.1398 30.9194 20.5806C33.3602 23.0214 33.3602 26.9786 30.9194 29.4194Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
