import { useState, useRef, useEffect, useMemo } from "react";
import "@mediapipe/face_detection";
import "@mediapipe/face_mesh";
import "@tensorflow/tfjs-backend-webgl";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import { HomeModule } from "../HomeModule/HomeModule";
import { ScanModule } from "../ScanModule/ScanModule";
import { TokenUrl, RecommendationUrl, AmplitudeUrl } from "../../constants";
import "./styles.css";
import { QuestionsModule } from "../QuestionsModule/QuestionsModule";
import { Results } from "../Results/Results";
import { ProductRecommendation } from "../ProductRecommendation/ProductRecommendation";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import { useTranslation } from "react-i18next";
import flowConfig from "../../config/flowConfig.json";
import useAiResults from "../../hooks/useAiResults";
import { useLocation, useNavigate } from "react-router-dom";
import { trackPageEvent } from "../../services/analyticsService";
import { generateUUID } from "../../services/uidService";
import { useUID } from "../../context/UIDContext";

export const Pipeline = () => {
  const { i18n } = useTranslation();
  const { aiResults, resetAiResults, fetchAiResults } = useAiResults();
  const [analysisPhase, setAnalysisPhase] = useState("home");
  const [token, updateToken] = useState("");
  const faceDetectorRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { updateUID, uid } = useUID();

  // If the user scans and go from Desktop to Mobile
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  useEffect(() => {
    const isScanned = params.get("scanned");

    if (isScanned) {
      const uid = generateUUID();
      updateUID(uid);

      trackPageEvent("webapp_user_scanned_qr", uid);
    }
  }, [location.search, updateUID, params]);

  // Track recommendation
  const recommendationsRef = useRef(null);

  const reset = () => {
    resetAiResults();
    recommendationsRef.current = null;
  };

  const fetchUserToken = () => {
    fetch(TokenUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ partnerName: flowConfig.partnerName }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Failed to fetch!");
      })
      .then((responseJson) => {
        updateToken(responseJson.token);
        setAnalysisPhase("scanModule");

        // save amplitude token in firebase
        fetch(AmplitudeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: responseJson.token,
          },
          body: JSON.stringify({ uuid: uid }),
        }).catch((error) => {
          console.error("Error:", error);
        });
      })
      .catch((error) => {
        setAnalysisPhase("error");
      });
  };

  const getRecommendations = async () => {
    if (recommendationsRef.current) return recommendationsRef.current;

    try {
      const response = await fetch(
        `${RecommendationUrl}?lng=${i18n.language}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          method: ["GET"],
        },
      );
      if (response.ok) {
        recommendationsRef.current = await response.json();
        return recommendationsRef.current;
      } else {
        throw new Error("Failed to fetch!");
      }
    } catch (error) {
      // Leaving it empty as no message is displayed
      return null;
    }
  };

  const loadModels = async () => {
    if (faceDetectorRef.current) return true;
    try {
      // Load Face Detector
      const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
      const detectorConfig = {
        runtime: "mediapipe",
        solutionPath: "libs/mediapipe",
      };
      faceDetectorRef.current = await faceLandmarksDetection.createDetector(
        model,
        detectorConfig,
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  const runFaceModel = async (video) => {
    if (faceDetectorRef.current === null) return [];

    const faces = await faceDetectorRef.current.estimateFaces(video, {
      flipHorizontal: true,
    });
    return faces;
  };

  const renderAnalysisPhase = () => {
    switch (analysisPhase) {
      case "scanModule":
        return (
          <ScanModule
            apiToken={token}
            faceModel={runFaceModel}
            runNextModule={() => setAnalysisPhase("userData")}
            onError={() => setAnalysisPhase("error")}
          />
        );
      case "userData":
        return (
          <QuestionsModule
            apiToken={token}
            fetchAIResults={() => fetchAiResults(token)}
            runNextModule={() => setAnalysisPhase("results")}
            onError={() => setAnalysisPhase("error")}
          />
        );
      case "results":
        return (
          <Results
            results={aiResults}
            onProductClick={() => setAnalysisPhase("products")}
            onExitClick={() => {
              setAnalysisPhase("home");
              params.delete("scanned");

              // Update the URL without reloading the page
              navigate(
                {
                  pathname: location.pathname,
                  search: params.toString(),
                },
                { replace: true },
              );
            }}
          />
        );
      case "products":
        return (
          <ProductRecommendation
            getRecommendations={getRecommendations}
            onBackClick={() => setAnalysisPhase("results")}
          />
        );
      case "error":
        return <ErrorPage onResetClick={() => setAnalysisPhase("home")} />;
      default:
        return (
          <HomeModule
            loadModels={loadModels}
            runNextModule={() => {
              reset();
              fetchUserToken();
            }}
          />
        );
    }
  };

  return <div className="pipeline">{renderAnalysisPhase()}</div>;
};
