import { useState } from "react";
import Button from "../Button/Button";
import { FormOptions } from "../FormOptions/FormOptions";
import { useTranslation } from "react-i18next";
import "./styles.css";

export const Form = ({ formData, onComplete }) => {
  const { t } = useTranslation(["customQuestions", "questionsModule"]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState(formData[0]);

  const loadDefault = () => {
    const initalOptionsValue = {};
    for (let i = 0; i < formData.length; i++) {
      switch (formData[i].type) {
        case "text":
          initalOptionsValue[formData[i].Q] = "";
          break;
        case "multi-options":
          initalOptionsValue[formData[i].Q] = [];
          break;
        default: // single-option
          initalOptionsValue[formData[i].Q] = "";
          break;
      }
    }
    return initalOptionsValue;
  };

  const [selectedOptions, setSelectedOptions] = useState(() => loadDefault());

  const onContinue = () => {
    if (activeQuestionIndex + 1 === formData.length) {
      onComplete(selectedOptions);
    } else {
      setActiveQuestionIndex((prevValue) => prevValue + 1);
      setActiveQuestion(formData[activeQuestionIndex + 1]);
    }
  };

  const handleSingleOptionInput = (value) => {
    setSelectedOptions((prevSelectedOption) => ({
      ...prevSelectedOption,
      [activeQuestion.Q]: value,
    }));
  };

  const handleMultiOptionInput = (value) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (value === activeQuestion.resetMapOption) {
        return {
          ...prevSelectedOptions,
          [activeQuestion.Q]: [value],
        };
      }

      let updatedOptions = [...prevSelectedOptions[activeQuestion.Q]];
      updatedOptions = updatedOptions.filter(
        (item) => item !== activeQuestion.resetMapOption,
      );

      const optionIndex = updatedOptions.indexOf(value);
      // Allow atleast one option to be always selected
      if (optionIndex !== -1 && updatedOptions.length === 1) {
        return {
          ...prevSelectedOptions,
          [activeQuestion.Q]: updatedOptions,
        };
      }

      if (optionIndex !== -1) {
        updatedOptions.splice(optionIndex, 1);
      } else {
        updatedOptions.push(value);
      }
      return {
        ...prevSelectedOptions,
        [activeQuestion.Q]: updatedOptions,
      };
    });
  };

  const handleTextInput = (event) => {
    if (event.target.value.length > 100) {
      return;
    }

    const numLines = event.target.value.split("\n").length;

    if (numLines > 1) {
      return;
    }

    setSelectedOptions((prevSelectedOptions) => {
      return {
        ...prevSelectedOptions,
        [activeQuestion.Q]: event.target.value,
      };
    });
  };

  return (
    <>
      <div className="form-content-text">
        <h4>{t(activeQuestion.Q)}</h4>
      </div>
      <div className="question-module-content">
        <div className="form-content-option">
          {activeQuestion.type === "text" ? (
            <div className="form-text-wrapper">
              <textarea
                rows={5}
                value={selectedOptions[activeQuestion.Q]}
                onChange={(e) => handleTextInput(e)}
                className="form-input-field"
              />
              <p className="form-input-limit">
                {100 - selectedOptions[activeQuestion.Q].length} / 100
              </p>
            </div>
          ) : (
            <FormOptions
              options={activeQuestion.keyMapOptions}
              multiAllowed={activeQuestion.type === "multi-options"}
              selectedOptions={selectedOptions[activeQuestion.Q]}
              onSelect={
                activeQuestion.type === "multi-options"
                  ? handleMultiOptionInput
                  : handleSingleOptionInput
              }
            />
          )}
        </div>
      </div>
      <div className="question-module-btn-wrapper">
        <Button
          text={t("questionsModule:continue")}
          onClick={() => onContinue()}
          disabled={
            selectedOptions[activeQuestion.Q].length < 1 &&
            activeQuestion.required
          }
        />
      </div>
    </>
  );
};
