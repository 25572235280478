import Webcam from "react-webcam";
import "./styles.css";
import { RiveAnimator } from "../RiveAnimator/RiveAnimator";
import { FaceRotationFilename, FrontCamConstraints } from "../../constants";
import {
  checkFaceRotation,
  checkFrontFace,
  videoToCoverCanvasCoordinates,
} from "./utilities";
import { useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useOpenCv } from "opencv-react";
import { checkLightQuality } from "./lightEstimate";

export const FaceScan = ({
  sideFaceEnabled,
  runFaceModel,
  addImage,
  onComplete,
  onError,
}) => {
  const { t } = useTranslation("scanModule");
  const { cv } = useOpenCv();

  const camContainerRef = useRef(null);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const maskRef = useRef(null);

  const maskBounds = useRef([0, 0, 0, 0]);
  const coverImageCoords = useRef([0, 0, 0, 0]);

  // Face capture parameters
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const [frontFaceAligned, setFrontFaceAligned] = useState(false);
  const [lightQuality, setLightQuality] = useState("dark");
  const [scanStep, setScanStep] = useState(1);

  // CSS Control parameters
  const [showCaptureFlash, setShowCaptureFlash] = useState(false);
  const isAnimationEnded = useRef(false);

  const resetCanvas = () => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const setMaskBounds = () => {
    const { x, y, width, height } = maskRef.current.getBoundingClientRect();
    maskBounds.current = [x, y, width, height];
  };

  const setCanvasOverlaySize = () => {
    const videoElement = webcamRef.current.video;

    // Update canvas dimensions - Explicitly not defined in CSS
    canvasRef.current.width = videoElement.offsetWidth;
    canvasRef.current.height = canvasRef.current.offsetHeight;

    // Get Image Coordinates for "objectFit: cover" property
    const containerRect = camContainerRef.current.getBoundingClientRect();
    const scaleX = containerRect.width / videoElement.videoWidth;
    const scaleY = containerRect.height / videoElement.videoHeight;
    const scale = Math.max(scaleX, scaleY);
    const actualWidth = videoElement.videoWidth * scale;
    const actualHeight = videoElement.videoHeight * scale;
    const actualXOffset = Math.round(
      Math.max((actualWidth - containerRect.width) / 2, 0),
    );
    const actualYOffset = Math.round(
      Math.max((actualHeight - containerRect.height) / 2, 0),
    );

    coverImageCoords.current = [
      actualXOffset,
      actualYOffset,
      actualWidth,
      actualHeight,
    ];
  };

  const updateAnimationState = () => {
    isAnimationEnded.current = true;
  };

  const runAIDetection = async () => {
    let faceSidePos = 0;
    let scanStep = 1;
    let captureTimer = null,
      sideDelayTimer = null;

    try {
      while (scanStep < 3) {
        if (isAnimationEnded.current === false) {
          await new Promise((resolve) =>
            requestAnimationFrame(() => resolve()),
          );
          continue;
        }

        const video = webcamRef.current.video;

        const faces = await runFaceModel(video);

        const canvasFaceCoords = videoToCoverCanvasCoordinates(
          faces,
          canvasRef.current.width,
          canvasRef.current.height,
          video.videoWidth,
          video.videoHeight,
          coverImageCoords.current[2],
          coverImageCoords.current[3],
          coverImageCoords.current[0],
          coverImageCoords.current[1],
        );

        if (canvasFaceCoords !== null) {
          // Clean up canvas
          // const ctx = canvasRef.current.getContext("2d");
          // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

          // For visualization of face detection
          // drawDetection(canvasFaceCoords, ctx);

          switch (scanStep) {
            case 1:
              // Test effect of ctx drawImage
              const lightQuality = checkLightQuality(cv, video, faces[0]);
              setLightQuality(lightQuality);

              const distanceSuccess = checkFrontFace(
                canvasFaceCoords,
                true,
                maskBounds.current[0],
                maskBounds.current[1],
                maskBounds.current[2],
                maskBounds.current[3],
              );
              setFrontFaceAligned(distanceSuccess);

              if (
                lightQuality === "good" &&
                distanceSuccess &&
                captureTimer === null
              ) {
                captureTimer = Date.now();
              }

              if (!distanceSuccess || lightQuality !== "good") {
                captureTimer = null;
              }

              // Step complete
              if (captureTimer !== null && Date.now() - captureTimer >= 800) {
                // Save this image and send it later
                const imageSrc = webcamRef.current.getScreenshot();
                addImage("front", imageSrc);
                setScanStep((prevValue) => prevValue + 1);
                scanStep += sideFaceEnabled ? 1 : 2;
                captureTimer = null;
              }
              break;
            case 2:
              if (faceSidePos > 1) {
                scanStep = 3;
                break;
              }

              // Clean-up canvas
              const ctx = canvasRef.current.getContext("2d");
              ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

              if (sideDelayTimer === null) {
                const maskNoseY =
                  0.65 * maskBounds.current[3] + maskBounds.current[1];
                const rotateSuccess = checkFaceRotation(
                  faceSidePos,
                  maskNoseY,
                  canvasFaceCoords,
                  ctx,
                );

                if (rotateSuccess) {
                  if (captureTimer === null) captureTimer = Date.now();
                } else {
                  captureTimer = null;
                }

                // Hold side position - 200ms time
                if (captureTimer && Date.now() - captureTimer >= 200) {
                  setShowCaptureFlash(true);
                  // Save the image and send it later
                  const imageSrc = webcamRef.current.getScreenshot();
                  addImage(FaceRotationFilename[faceSidePos], imageSrc);
                  sideDelayTimer = Date.now();
                }
              }

              // Wait for flash to complete - 110ms animation
              if (sideDelayTimer && Date.now() - sideDelayTimer >= 110) {
                faceSidePos += 1;
                captureTimer = null;
                sideDelayTimer = null;
                setShowCaptureFlash(false);
              }
              break;
            default:
              break;
          }
        }

        await new Promise((resolve) => requestAnimationFrame(() => resolve()));
      }
      // Scan complete
      resetCanvas();
      onComplete();
    } catch (error) {
      console.log(error);
      setCameraLoaded(false);
      onError();
      return;
    }
  };

  return (
    <>
      <div ref={camContainerRef} className="webcam">
        <Webcam
          audio={false}
          mirrored={true}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={FrontCamConstraints}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          forceScreenshotSourceSize={true}
          imageSmoothing={true}
          onUserMedia={() => {
            const timerId = setTimeout(() => {
              runAIDetection();
              setCameraLoaded(true);
            }, 500);
            return () => clearTimeout(timerId);
          }}
          onLoadedMetadata={() => setCanvasOverlaySize()}
          onUserMediaError={() => onError()}
        />
      </div>
      {!cameraLoaded && (
        <div className="webcam loading">
          <CircularProgress
            disableShrink
            color="inherit"
            size={40}
            thickness={4}
          />
          <p className="scan-interim-text">{t("start-camera")}</p>
        </div>
      )}
      <div className="partial-overlay detection-canvas">
        <canvas
          ref={canvasRef}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      {cameraLoaded && scanStep === 1 && (
        <>
          <div className="partial-overlay mask">
            <img
              ref={maskRef}
              src="FaceMask_Green.svg"
              width={100}
              alt="Face Position"
              style={{
                width: "90vmin",
                height: "90vmin",
                maxWidth: "480px",
                maxHeight: "480px",
                opacity: frontFaceAligned && lightQuality === "good" ? 1 : 0,
              }}
              onLoad={() => setMaskBounds()}
            />
          </div>
          <div className="partial-overlay mask">
            <div
              style={{
                width: "90vmin",
                height: "90vmin",
                maxWidth: "480px",
                maxHeight: "480px",
                opacity: frontFaceAligned && lightQuality === "good" ? 0 : 1,
              }}
            >
              <RiveAnimator
                fileName="FaceMask_Animation.riv"
                animationName="Animation"
                onAnimationEnd={updateAnimationState}
              />
            </div>
          </div>
          <div className="status-tiles">
            <div className={`tile ${frontFaceAligned ? "success" : ""}`}>
              <h4 className="tile-title">{t("face-position")}</h4>
              <p className="tile-info">
                {t(frontFaceAligned ? "face-aligned" : "face-not-aligned")}
              </p>
            </div>
            <div className={`tile ${lightQuality === "good" ? "success" : ""}`}>
              <h4 className="tile-title">{t("face-light")}</h4>
              <p className="tile-info">{t(`light-${lightQuality}`)}</p>
            </div>
          </div>
        </>
      )}
      {cameraLoaded && scanStep === 2 && (
        <div className={`full-overlay ${showCaptureFlash ? "flash" : ""}`} />
      )}
      {cameraLoaded && (
        <>
          <div className="scan-grid-bottom-bg"></div>
          <div className="scan-grid-bottom">
            <p className="white-text">
              {scanStep === 1 ? t("facePosition") : t("faceSides")}
            </p>
          </div>
        </>
      )}
    </>
  );
};
