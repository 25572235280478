import "./App.css";
import { AppRouter } from "./components/AppRouter/AppRouter";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "./theme.css";
import { UIDProvider } from "./context/UIDContext";
import { OpenCvProvider } from "opencv-react";

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <OpenCvProvider openCvPath="/libs/opencv.js">
          <UIDProvider>
            <AppRouter />
          </UIDProvider>
        </OpenCvProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
