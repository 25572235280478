// Opencv algorithm based on leftCheek, rightCheek and Forehead Light values
export const checkLightQuality = (cv, video, face) => {
  const boxCanvas = document.createElement("canvas");
  const boxCanvasCtx = boxCanvas.getContext("2d");
  const offsetX = video.videoWidth - face.box.xMin;
  const xCoord = video.videoWidth - face.box.width - face.box.xMin;
  boxCanvas.width = face.box.width;
  boxCanvas.height = face.box.height;
  boxCanvasCtx.drawImage(
    video,
    xCoord,
    face.box.yMin,
    face.box.width,
    face.box.height,
    0,
    0,
    boxCanvas.width,
    boxCanvas.height,
  );

  // Convert src image from RGBA to Y-color (Luma)
  let yChannel = convertRGBA2Luma(cv, boxCanvas);

  // Get light quality for leftCheek, rightCheek and forehead regions
  // Disabling Forehead, because for people with "Hair bangs" it covers foreahead and affect light estimator
  // const foreheadQuality = getLightQuality(
  //   cv,
  //   yChannel,
  //   offsetX,
  //   face.box.yMin,
  //   boxCanvas.width,
  //   boxCanvas.height,
  //   face.keypoints[67],
  //   face.keypoints[105],
  //   face.keypoints[297],
  //   face.keypoints[334],
  // );

  const leftCheekQuality = getLightQuality(
    cv,
    yChannel,
    offsetX,
    face.box.yMin,
    boxCanvas.width,
    boxCanvas.height,
    face.keypoints[116],
    face.keypoints[147],
    face.keypoints[120],
    face.keypoints[203],
  );

  const rightCheekQuality = getLightQuality(
    cv,
    yChannel,
    offsetX,
    face.box.yMin,
    boxCanvas.width,
    boxCanvas.height,
    face.keypoints[349],
    face.keypoints[423],
    face.keypoints[340],
    face.keypoints[376],
  );

  const finalLightQuality = getFinalQuality(
    "good", //foreheadQuality
    leftCheekQuality,
    rightCheekQuality,
  );

  yChannel.delete();
  return finalLightQuality;
};

const convertRGBA2Luma = (cv, imageCanvas) => {
  const imageCanvasCtx = imageCanvas.getContext("2d");
  let src = new cv.Mat(imageCanvas.height, imageCanvas.width, cv.CV_8UC4);
  let interim = new cv.Mat(imageCanvas.height, imageCanvas.width, cv.CV_8UC3);
  let dst = new cv.Mat(imageCanvas.height, imageCanvas.width, cv.CV_8UC3);

  src.data.set(
    imageCanvasCtx.getImageData(0, 0, imageCanvas.width, imageCanvas.height)
      .data,
  );

  // No direct conversion available from RGBA to YCrCb
  cv.cvtColor(src, interim, cv.COLOR_RGBA2RGB);
  cv.cvtColor(interim, dst, cv.COLOR_RGB2YCrCb);
  let ycrcbPlanes = new cv.MatVector();
  // Split the Mat
  cv.split(dst, ycrcbPlanes);
  // Get Y channel
  let yChannel = ycrcbPlanes.get(0);

  src.delete();
  interim.delete();
  dst.delete();
  ycrcbPlanes.delete();

  return yChannel;
};

// Check light quality based on rect polygon and also adjust for mirror
const getLightQuality = (
  cv,
  lumaChannel,
  offsetX,
  offsetY,
  canvasWidth,
  canvasHeight,
  topLeftPoint,
  bottomLeftPoint,
  topRightPoint,
  bottomRightPoint,
) => {
  const mask = new cv.Mat.zeros(canvasHeight, canvasWidth, cv.CV_8UC1);
  let contours = new cv.MatVector();
  let contour = new cv.matFromArray(1, 4, cv.CV_32SC2, [
    offsetX - topLeftPoint.x,
    topLeftPoint.y - offsetY,
    offsetX - bottomLeftPoint.x,
    bottomLeftPoint.y - offsetY,
    offsetX - topRightPoint.x,
    topRightPoint.y - offsetY,
    offsetX - bottomRightPoint.x,
    bottomRightPoint.y - offsetY,
  ]);
  contours.push_back(contour);
  cv.drawContours(mask, contours, 0, new cv.Scalar(255), -1);
  const maskMean = new cv.Mat();
  const maskStd = new cv.Mat();
  cv.meanStdDev(lumaChannel, maskMean, maskStd, mask);

  let lightQuality = "dark";
  if (
    maskMean.data64F[0] >= 140 &&
    maskMean.data64F[0] <= 200 &&
    maskStd.data64F[0] <= 60
  ) {
    lightQuality = "good";
  } else {
    // Check is it closer to bright limit or dark
    lightQuality = maskMean.data64F[0] - 170 > 0 ? "bright" : "dark"; // 170 is center point b/w 140 and 200
  }

  //   console.log("Mean", maskMean.data64F[0]);
  //   console.log("Std", maskStd.data64F[0]);

  // Clean-up
  mask.delete();
  contours.delete();
  contour.delete();
  maskMean.delete();
  maskStd.delete();

  return lightQuality;
};

// Check if all values are "good" otherwise return the most occuring value either "dark" or "bright"
const getFinalQuality = (value1, value2, value3) => {
  const values = [value1, value2, value3];

  if (values.every((value) => value === "good")) {
    return "good";
  }

  // Filter out only dark or bright
  const filteredValues = values.filter(
    (value) => value === "dark" || value === "bright",
  );

  const darkCounts = filteredValues.filter((value) => value === "dark");
  const brightCounts = filteredValues.filter((value) => value === "bright");

  // If total bright more than dark than return bright, default is dark since it occurs more often
  return brightCounts > darkCounts ? "bright" : "dark";
};
