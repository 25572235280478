export const CheckMarkIcon = ({ thickness, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      className={className ? className : ""}
    >
      <path
        d="M16.2307 1L7.10811 14L1 10.0907"
        stroke="currentColor"
        strokeWidth={thickness ? thickness : "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
