import { useEffect, useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { ProductCards } from "../ProductCards/ProductCards";
import CircularProgress from "@mui/material/CircularProgress";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { trackPageEvent } from "../../services/analyticsService";
import { useUID } from "../../context/UIDContext";

export const ProductRecommendation = ({ getRecommendations, onBackClick }) => {
  const { t } = useTranslation("recommendation");
  const [products, setProducts] = useState(null);
  const { uid } = useUID();

  useEffect(() => {
    getRecommendations().then((res) => {
      if (res) {
        setProducts(res);
      }
    });
  }, [getRecommendations]);

  // Track user visits Product Recomandation Page
  useEffect(() => {
    // Track user visits Results Page
    trackPageEvent("webapp_user_visited_products_page", uid, "products");
  }, [uid]);

  return (
    <div className="product-recommend">
      <div className="logo-bar">
        <img className="logo-img" src="logo.png" alt="Logo" />
        <IoChevronBackOutline
          className="back-icon"
          onClick={() => onBackClick()}
        />
      </div>
      <div className="product-individual">
        <h4 className="page-info">{t("title-1")}</h4>
        <h4 className="page-info">{t("title-2")}</h4>
      </div>
      {products ? (
        <>
          {Object.entries(products).map(
            ([categoryName, categorizedProducts]) => (
              <div key={categoryName} className="products-category-wrapper">
                <h4 className="products-title">{categoryName}</h4>
                <ProductCards
                  key={categoryName}
                  products={categorizedProducts}
                />
              </div>
            ),
          )}
        </>
      ) : (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            color: "#000",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      )}
      <button
        className="styled-button primary-background border-nav"
        style={{
          zIndex: "250",
          position: "fixed",
          bottom: "0px",
          marginBottom: "1rem",
          cursor: "pointer",
          opacity: `${window.LiveChatWidget ? "1" : "0"}`,
          visibility: `${window.LiveChatWidget ? "visible" : "hidden"}`,
        }}
        onClick={() => {
          if (window.LiveChatWidget) {
            window.LiveChatWidget.call("maximize");
          }
        }}
      >
        {t("consultation-button-text")}
      </button>
    </div>
  );
};
