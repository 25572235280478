import { useState } from "react";
import "./styles.css";
import { WelcomePage } from "./WelcomePage";
import { ValidatePage } from "./ValidatePage";
import { LoadingPage } from "./LoadingPage";

export const HomeModule = ({ loadModels, runNextModule }) => {
  const [currentStep, updateCurrentStep] = useState(0);

  const loadNextStep = () => {
    if (currentStep === 2) {
      runNextModule();
    } else {
      updateCurrentStep((prevState) => prevState + 1);
    }
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <ValidatePage onFinish={loadNextStep} />;
      case 2:
        return (
          <LoadingPage onBegin={() => loadModels()} onFinish={loadNextStep} />
        );
      default:
        return <WelcomePage onFinish={loadNextStep} />;
    }
  };

  return <div className="home">{renderCurrentStep()}</div>;
};
