import { useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

export const TabularCard = ({ tabNames }) => {
  const { t } = useTranslation(["aiResults", "customAiInfo"]);
  const [activeTab, setActiveTab] = useState(tabNames[0]);

  return (
    <div className="model-card">
      <div className="button-container">
        {tabNames.map((tab) => (
          <button
            key={tab}
            className={`tab-button ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {t("aiResults:" + tab)}
          </button>
        ))}
      </div>
      <div className="content-container">
        <p className="content-text">
          {t("customAiInfo:" + activeTab.toLowerCase() + "-card-info")}
        </p>
      </div>
    </div>
  );
};
