export const ExclamationMarkIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="93"
      viewBox="0 0 16 93"
      fill="none"
      className={className ? className : ""}
    >
      <path
        d="M8 8V52.5"
        stroke="currentColor"
        strokeWidth="15.8264"
        strokeLinecap="round"
      />
      <circle cx="8" cy="85" r="8" fill="currentColor" />
    </svg>
  );
};
