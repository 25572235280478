import { useEffect, useState } from "react";
import "./styles.css";
import { FaceScan } from "./FaceScan";
import { FaceTutorial } from "./FaceTutorial";
import flowConfig from "../../config/flowConfig.json";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import useScanImages from "../../hooks/useScanImages";
import { AddImageUrl } from "../../constants";
import { HeadInitialize } from "./HeadInitialize";
import { HeadScan } from "./HeadScan";

export const ScanModule = ({ apiToken, faceModel, runNextModule, onError }) => {
  const { t } = useTranslation("scanModule");
  const { images, addImage, uploadImages } = useScanImages();

  const [currentStep, updateCurrentStep] = useState(
    flowConfig.FaceModuleFlags.tutorialEnabled ? 0 : 1,
  );

  useEffect(() => {
    if (currentStep === 4 && images.length > 0) {
      // Cloud API
      const timerId = setTimeout(() => {
        uploadImages(AddImageUrl, apiToken).then((response) => {
          if (response) {
            runNextModule();
          } else {
            onError();
          }
        });
      }, 1000);

      return () => clearTimeout(timerId);
    }
  });

  const loadNextStep = () => {
    updateCurrentStep((prevState) => {
      if (prevState === 1 && !flowConfig.HeadModuleFlags.isEnabled) {
        return 4;
      } else {
        return prevState + 1;
      }
    });
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <FaceScan
            sideFaceEnabled={flowConfig.FaceModuleFlags.sideScanEnabled}
            addImage={addImage}
            runFaceModel={faceModel}
            onComplete={loadNextStep}
            onError={onError}
          />
        );
      case 2:
        return (
          <HeadInitialize
            onSkipClick={() => updateCurrentStep(4)}
            onContinueClick={loadNextStep}
          />
        );
      case 3:
        return (
          <HeadScan
            onBack={() => updateCurrentStep(2)}
            onFinish={loadNextStep}
            addImage={addImage}
          />
        );
      case 4:
        return (
          <div className="webcam loading">
            <CircularProgress
              disableShrink
              color="inherit"
              size={40}
              thickness={4}
            />
            <p className="scan-interim-text">
              {flowConfig.FaceModuleFlags.sideScanEnabled
                ? t("upload-images")
                : t("upload-image")}
            </p>
          </div>
        );
      default:
        return (
          <FaceTutorial
            onComplete={loadNextStep}
            sideFaceEnabled={flowConfig.FaceModuleFlags.sideScanEnabled}
          />
        );
    }
  };

  return <div className="home">{renderCurrentStep()}</div>;
};
