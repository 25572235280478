import { Circle } from "./Circle";
import "./styles.css";

// const CirclesLast = [
//   2, 2, 2, 4, 4, 4, 5, 5, 5, 6, 6, 7, 8, 8, 8, 10, 10, 10, 8, 10, 12, 8, 10, 10,
//   10, 8, 8, 8, 7, 6, 6, 5, 5, 5, 4, 4, 4, 2, 2, 2,
// ];

const CirclesLast = [
  6, 7, 8, 8, 8, 10, 10, 10, 8, 10, 12, 8, 10, 10, 10, 8, 8, 8, 7, 6,
];

const CirclesMiddle = [
  2, 2, 2, 4, 4, 4, 5, 5, 5, 6, 6, 7, 8, 8, 8, 6, 5, 8, 8, 6, 7, 8, 5, 6, 7, 8,
  8, 8, 7, 6, 6, 5, 5, 5, 4, 4, 4, 2, 2, 2,
];

export const CirclePulseAnimation = () => {
  return (
    <div className="pulse-animation-conatiner">
      <div className="loading-wave-wrapper">
        {CirclesMiddle.map((size, index) => (
          <Circle
            key={index}
            radius={size}
            index={index}
            offsetX={20}
            delay={1.5}
          />
        ))}
      </div>
      <div className="loading-wave-wrapper">
        {CirclesLast.map((size, index) => (
          <Circle key={index} radius={size} index={index + 40} offsetX={50} />
        ))}
      </div>
    </div>
  );
};
