import { useEffect, useRef } from "react";
import { Tween, Circ } from "gsap/gsap-core";
import "./styles.css";

export const ScifiWireAnimation = ({ targetRadius }) => {
  const canvasRef = useRef(null);

  // animation
  const initAnimation = () => {
    canvasRef.current.width = canvasRef.current.clientWidth;
    canvasRef.current.height = canvasRef.current.clientHeight;

    // create points
    let points = [];
    const width = 2 * targetRadius;
    const height = 2 * targetRadius;
    const widthDelta = width / 10;
    const heightDelta = height / 10;
    for (let x = -widthDelta; x < width + widthDelta; x += widthDelta) {
      for (let y = -heightDelta; y < height + heightDelta; y += heightDelta) {
        var px =
          canvasRef.current.width / 2 -
          targetRadius +
          x +
          (Math.random() * width) / 10;
        var py =
          canvasRef.current.height / 2 -
          targetRadius +
          y +
          (Math.random() * height) / 10;
        var radius = 2 + Math.random() * 2;
        points.push({
          x: px,
          y: py,
          originX: px,
          originY: py,
          closest: [],
          radius: radius,
          opacity: 0.05,
        });
      }
    }

    // for each point find the 3 closest points
    for (let i = 0; i < points.length; i++) {
      var closest = [];
      var p1 = points[i];
      for (let j = 0; j < points.length; j++) {
        var p2 = points[j];
        if (!(p1 === p2)) {
          var placed = false;
          for (let k = 0; k < 3; k++) {
            if (!placed) {
              if (closest[k] === undefined) {
                closest[k] = p2;
                placed = true;
              }
            }
          }

          for (let k = 0; k < 3; k++) {
            if (!placed) {
              if (getDistance(p1, p2) < getDistance(p1, closest[k])) {
                closest[k] = p2;
                placed = true;
              }
            }
          }
        }
      }
      p1.closest = closest;
    }

    const canvasStyle = window.getComputedStyle(canvasRef.current);
    const canvasColor = canvasStyle.color;
    const rgbCanvasColor = canvasColor.replace(/[^\d,]/g, "").split(",");

    const animate = () => {
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        const target = {
          x: canvasRef.current.width / 2,
          y: canvasRef.current.height / 2,
        };
        for (var i in points) {
          if (Math.abs(getDistance(target, points[i])) < 4000) {
            points[i].opacity = 0.1;
          } else if (Math.abs(getDistance(target, points[i])) < 20000) {
            points[i].opacity = 0.05;
          } else if (Math.abs(getDistance(target, points[i])) < 40000) {
            points[i].opacity = 0.02;
          } else {
            points[i].opacity = 0;
          }
          drawLines(ctx, rgbCanvasColor, points[i]);
          drawCircle(ctx, rgbCanvasColor, points[i]);
        }

        requestAnimationFrame(animate);
      } else return;
    };

    animate();
    for (var i in points) {
      shiftPoint(points[i]);
    }
  };

  function shiftPoint(p) {
    Tween.to(p, {
      duration: 2 + 1 * Math.random(),
      x: Math.round(p.originX - 50 + Math.random() * 100),
      y: Math.round(p.originY - 50 + Math.random() * 100),
      ease: Circ.easeInOut,
      onComplete: function () {
        shiftPoint(p);
      },
    });
  }

  // Canvas manipulation
  function drawLines(ctx, color, p) {
    for (let i in p.closest) {
      ctx.beginPath();
      ctx.moveTo(p.x, p.y);
      ctx.lineTo(p.closest[i].x, p.closest[i].y);
      ctx.strokeStyle =
        "rgba(" +
        color[0] +
        "," +
        color[1] +
        "," +
        color[2] +
        "," +
        p.opacity +
        ")";
      ctx.stroke();
    }
  }

  function drawCircle(ctx, color, p) {
    ctx.beginPath();
    ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, false);
    ctx.fillStyle =
      "rgba(" +
      color[0] +
      "," +
      color[1] +
      "," +
      color[2] +
      "," +
      p.opacity +
      ")";
    ctx.fill();
  }

  // Util
  function getDistance(p1, p2) {
    return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
  }

  useEffect(() => {
    if (canvasRef) {
      initAnimation();
    }
  });

  return <canvas ref={canvasRef} className="bg-animation" />;
};
