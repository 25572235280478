import { useEffect, useState } from "react";
import Button from "../Button/Button";
import "./styles.css";
import { UpdateUserInfoUrl } from "../../constants";
import { useTranslation } from "react-i18next";
import { InfoCollection } from "./InfoCollection";
import UserQuestions from "../../config/questions.json";
import flowConfig from "../../config/flowConfig.json";
import { ThickCheckMarkIcon } from "../icons/ThickCheckMarkIcon";
import { CirclePulseAnimation } from "../animations/CirclePulseAnimation/CirclePulseAnimation";
import useSendEmail from "../../hooks/useSendEmail";

export const QuestionsModule = ({
  apiToken,
  fetchAIResults,
  runNextModule,
  onError,
}) => {
  const { t, i18n } = useTranslation("questionsModule");
  const [currentStep, setCurrentStep] = useState(0);

  const { addUserEmail, sendEmail } = useSendEmail();

  const [fetchResultsComplete, setFetchResultsComplete] = useState(false);
  const [isResultResponseSuccess, setIsResultResponseSuccess] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSaveDone, setIsSaveDone] = useState(false);

  useEffect(() => {
    fetchResults();
    const timerId = setTimeout(async () => {
      if (
        flowConfig.QuestionsModuleFlags.emailEnabled ||
        UserQuestions.length > 0
      ) {
        setCurrentStep(1);
      } else {
        setCurrentStep(2);
        setIsEmailSent(true);
        saveSelectedInfo({ lang: i18n.language });
      }
    }, 1500); // Initial wait for transition

    return () => clearTimeout(timerId);
  }, []);

  const fetchResults = async () => {
    const resResponse = await fetchAIResults();
    if (resResponse) {
      setIsResultResponseSuccess(true);
    } else {
      setIsResultResponseSuccess(false);
    }
    setFetchResultsComplete(true);
  };

  useEffect(() => {
    if (currentStep === 2 && fetchResultsComplete && !isResultResponseSuccess) {
      onError();
    }
  }, [currentStep, fetchResultsComplete, isResultResponseSuccess, onError]);

  useEffect(() => {
    if (
      currentStep === 2 &&
      fetchResultsComplete &&
      isResultResponseSuccess &&
      !isEmailSent
    ) {
      if (flowConfig.QuestionsModuleFlags.emailEnabled)
        sendEmail(i18n.language, apiToken);

      setIsEmailSent(true);
    }
  }, [
    i18n.language,
    apiToken,
    isEmailSent,
    currentStep,
    fetchResultsComplete,
    isResultResponseSuccess,
    sendEmail,
  ]);

  const saveSelectedInfo = async (userData) => {
    try {
      const res = await fetch(UpdateUserInfoUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: apiToken,
        },
        body: JSON.stringify(userData),
      });
      if (!res.ok) {
        throw new Error("Unable to send Questionnaire data!");
      }
    } catch (error) {
      // TODO: Add error tracking
    }
    setIsSaveDone(true);
  };

  return (
    <div
      className={`questions-module ${currentStep === 1 ? "five-row-grid" : ""}`}
    >
      {currentStep === 2 &&
      isEmailSent &&
      isSaveDone &&
      isResultResponseSuccess &&
      fetchResultsComplete ? (
        <div className="analyze-progress">
          <ThickCheckMarkIcon className="result-ready-animaton" />
          <h4 className="analyze-title-success">
            {t("results-success-title")}
          </h4>
          <p className="analyze-info-success">{t("results-ready")}</p>
        </div>
      ) : (
        <div className="analyze-progress">
          <CirclePulseAnimation />
          {currentStep !== 1 && (
            <p className="analyze-text-animated">{t("analyzing")}...</p>
          )}
        </div>
      )}
      {currentStep === 1 && (
        <InfoCollection
          emailEnabled={flowConfig.QuestionsModuleFlags.emailEnabled}
          updateEmail={addUserEmail}
          questionsData={UserQuestions}
          onComplete={(data) => {
            setCurrentStep(2);
            saveSelectedInfo(data);
          }}
        />
      )}
      {currentStep === 2 &&
        isEmailSent &&
        isSaveDone &&
        isResultResponseSuccess &&
        fetchResultsComplete && (
          <div className="question-module-btn-wrapper">
            <Button text={t("continue")} onClick={() => runNextModule()} />
          </div>
        )}
    </div>
  );
};
