import gsap from "gsap";
import { Bounce } from "gsap/gsap-core";
import { useEffect } from "react";
import "./styles.css";

export const Circle = ({ index, offsetX, radius, delay }) => {
  useEffect(() => {
    const centerX = offsetX;
    const distanceFromCenter = Math.abs(index - centerX);
    const baseAmplitude =
      distanceFromCenter > 6
        ? 20 + Math.random() * 4 - 2
        : 60 - 2 * distanceFromCenter;
    const norm = index / (20 - 1);
    const amplitude =
      Math.random() < 0.5 ? (-1 * baseAmplitude) / 2 : baseAmplitude / 2;

    gsap
      .to(`#circle-${index}`, {
        y: -amplitude,
        duration: 1.1,
        delay: delay,
        repeat: -1,
        yoyo: true,
        ease: Bounce.easeIn,
      })
      .progress(norm * 15);
  }, [index, radius, delay, offsetX]);

  return (
    <div
      id={`circle-${index}`}
      className="circle"
      style={{
        width: `${radius}px`,
        height: `${radius}px`,
      }}
    ></div>
  );
};
