import { useState } from "react";
import { AiResultsUrl } from "../constants";

const useAiResults = () => {
  const [aiResults, setAiResults] = useState(null);

  const resetAiResults = () => {
    setAiResults(null);
  };

  const fetchAiResults = (apiToken) => {
    // Fetch results with AI
    const res = fetch(`${AiResultsUrl}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: apiToken,
      },
      method: "GET",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Failed to fetch!");
      })
      .then((responseJson) => {
        setAiResults(responseJson);
        return true;
      })
      .catch((error) => {
        setAiResults(null);
        return false;
      });

    return res;
  };

  return { aiResults, resetAiResults, fetchAiResults };
};

export default useAiResults;
