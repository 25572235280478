import { useEffect, useState } from "react";
import "./styles.css";

const Button = ({
  text = "",
  onClick = undefined,
  usePrimaryColor = true,
  disabled = false,
  delay = null,
  isRounded = false,
  useDefaultColor = false,
}) => {
  const [styleName, setSytleName] = useState(
    delay !== null ? "hidden-button" : "",
  );
  const [isDelayComplete, setIsDelayComplete] = useState(false);

  useEffect(() => {
    if (delay === null) return;

    const timerId = setTimeout(() => {
      setIsDelayComplete(true);
      setSytleName("hidden-button slide-up");
    }, delay * 1000);

    return () => clearTimeout(timerId);
  }, [delay]);

  const roundSize = isRounded ? "border-smooth" : "border-nav";
  const buttonColor = useDefaultColor
    ? "white-background"
    : usePrimaryColor
      ? "primary-background"
      : "secondary-background";

  return (
    <button
      className={`styled-button ${buttonColor} ${styleName} ${roundSize}`}
      onClick={onClick}
      disabled={delay ? !isDelayComplete : disabled}
    >
      {text}
    </button>
  );
};

export default Button;
