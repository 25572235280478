import Webcam from "react-webcam";
import { BackCamConstraints, HeadRotationFilename } from "../../constants";
import { useState, useRef, useEffect } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

export const HeadScan = ({ addImage, onBack, onFinish }) => {
  const { t } = useTranslation("scanModule");
  const webcamRef = useRef(null);

  const [showCaptureFlash, setShowCaptureFlash] = useState(false);
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const [scanStep, setScanStep] = useState(0);

  useEffect(() => {
    if (!showCaptureFlash) return;
  }, [showCaptureFlash]);

  const onCaptureImage = (headStep) => {
    setShowCaptureFlash(true);

    // Save the image and send it later
    const imageSrc = webcamRef.current.getScreenshot();
    addImage(HeadRotationFilename[headStep], imageSrc);

    const timerId = setTimeout(() => {
      setShowCaptureFlash(false);

      setScanStep((prevValue) => prevValue + 1);
      if (scanStep === 2) onFinish();
    }, 110); // Delay here is same as flash delay in css

    return () => clearTimeout(timerId);
  };

  return (
    <>
      <div className="webcam">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={BackCamConstraints}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          forceScreenshotSourceSize={true}
          imageSmoothing={true}
          onUserMedia={() => {
            const timerId = setTimeout(() => setCameraLoaded(true), 500);
            return () => clearTimeout(timerId);
          }}
          onUserMediaError={() => setCameraLoaded(false)}
        />
      </div>
      {scanStep === 0 && (
        <div className="head-scan-img">
          <img
            src="HairDresser_Guide.svg"
            width={100}
            alt="Head Images Guide"
            style={{
              width: "80vmin",
              maxWidth: "480px",
              height: "80vmin",
            }}
          />
        </div>
      )}
      <div
        className={`head-scan-tutorial-background ${
          scanStep > 0 ? "hidden" : ""
        }`}
      />
      {scanStep > 0 && (
        <div className={`full-overlay ${showCaptureFlash ? "flash" : ""}`} />
      )}
      <div className="head-scan-text-container">
        <p className="head-scan-text">{t(`head-scan-step-${scanStep}`)}</p>
      </div>
      <div className="head-scan-interact">
        {scanStep === 0 ? (
          <div
            className={`head-tutorial-buttons ${
              cameraLoaded ? "show-up-buttons" : ""
            }`}
          >
            <button
              className="head-tutorial-back-button"
              onClick={() => onBack()}
            >
              {t("back")}
            </button>
            <button
              className="head-tutorial-start-button"
              onClick={() => setScanStep(1)}
            >
              {t("start")}
            </button>
          </div>
        ) : (
          <button
            className="round-capture-button"
            onClick={() => onCaptureImage(scanStep - 1)}
          />
        )}
      </div>
    </>
  );
};
