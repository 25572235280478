export const BalanceLightIcon = ({ className }) => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        d="M24.75 17C24.75 21.0041 21.5041 24.25 17.5 24.25C13.4959 24.25 10.25 21.0041 10.25 17C10.25 12.9959 13.4959 9.75 17.5 9.75C21.5041 9.75 24.75 12.9959 24.75 17Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M17.5 26.9999V32.9999M27.5 16.9999H33.5M1.5 16.9999H7.5M17.5 1V7M28.7847 5.71868L24.542 9.96132M6.21533 5.71868L10.458 9.96132M6.21533 28.2812L10.458 24.0385M28.7846 28.2812L24.542 24.0385"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17.5 23C20.8137 23 23.5 20.3137 23.5 17C23.5 13.6863 20.8137 11 17.5 11V23Z"
        fill="currentColor"
      />
    </svg>
  );
};
