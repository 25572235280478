import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Pipeline } from "../Pipeline/Pipeline";

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Pipeline />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
};
