export const FrontCamConstraints = {
  facingMode: "user",
  frameRate: { ideal: 30 },
  width: 3000,
  height: 2000,
};

export const BackCamConstraints = {
  facingMode: "environment",
  frameRate: { ideal: 30 },
  width: 3000,
  height: 2000,
};

export const AIColorCodes = {
  Dull: "#e17177",
  Glossy: "#b7ee71",
  Dry: "#b7ee71",
  Normal: "#e17177",
  Oily: "#83c3ff",
};

export const FaceRotationAngles = [{ x: 0.25 }, { x: 0.75 }];

export const FaceRotationFilename = ["left", "right"];
export const HeadRotationFilename = ["top", "back"];

export const TokenUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://generateusertoken-vy5w4izr4a-ey.a.run.app"
    : "https://generateusertoken-j7okuxulxa-ey.a.run.app";

export const AddImageUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://uploadimages-vy5w4izr4a-ey.a.run.app"
    : "https://uploadimages-j7okuxulxa-ey.a.run.app";

export const UpdateUserInfoUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://uploadformdata-vy5w4izr4a-ey.a.run.app"
    : "https://uploadformdata-j7okuxulxa-ey.a.run.app";

export const AmplitudeUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://uploadamplitudedata-vy5w4izr4a-ey.a.run.app"
    : "https://uploadamplitudedata-j7okuxulxa-ey.a.run.app";

export const AiResultsUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://getairesultsv2-vy5w4izr4a-ey.a.run.app"
    : "https://getairesultsv2-j7okuxulxa-ey.a.run.app";

export const EmailUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://sendemailv2-vy5w4izr4a-ey.a.run.app"
    : "https://sendemailv2-j7okuxulxa-ey.a.run.app";

export const RecommendationUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://getrecommendations-vy5w4izr4a-ey.a.run.app"
    : "https://getrecommendations-j7okuxulxa-ey.a.run.app";
