export const ThickCheckMarkIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="95"
      viewBox="0 0 107 95"
      fill="none"
      className={className ? className : ""}
    >
      <path
        d="M99 8.61328L44.4935 86.2872L7.99805 62.9298"
        stroke="currentColor"
        strokeWidth="15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
