import { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { AIColorCodes } from "../../constants";

export const PercentageCircles = ({ percentages }) => {
  const { t } = useTranslation("aiResults");
  const [circlesScore, setCirclesScores] = useState(null);

  useEffect(() => {
    const initialScores = {};
    for (const key in percentages) {
      initialScores[key] = 0;
    }
    setCirclesScores(initialScores);

    const timerId = setTimeout(() => {
      setCirclesScores(percentages);
    }, 500);

    return () => clearTimeout(timerId);
  }, [percentages]);

  return (
    <div className="overlay-circles">
      {circlesScore &&
        Object.entries(circlesScore).map(([key, value], index) => (
          <div key={index} className={`percentage-circle position-${index}`}>
            <CircularProgressbarWithChildren
              strokeWidth={5}
              value={value}
              background
              styles={buildStyles({
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                textColor: "black",
                pathColor: AIColorCodes[key],
                trailColor: "white",
              })}
            >
              <div className="percentage-value">{value}</div>
              <div className="category-text">{t(key)}</div>
            </CircularProgressbarWithChildren>
          </div>
        ))}
    </div>
  );
};
