import "./styles.css";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { FaPlay } from "react-icons/fa";

export const FaceTutorial = ({ sideFaceEnabled, onComplete }) => {
  const { t } = useTranslation("scanModule");

  return (
    <>
      <div className="full-overlay">
        <video
          src={`demo-videos/tutorial-${sideFaceEnabled ? "full" : "half"}.mov`}
          playsInline
          autoPlay
          loop
          muted
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <FaPlay className="tutorial-play-icon" />
      <div className="scan-grid-bottom-bg"></div>
      <div className="scan-grid-bottom">
        <Button
          text={t("continue")}
          delay={sideFaceEnabled ? 6 : 3}
          onClick={() => onComplete()}
          useDefaultColor={true}
        />
      </div>
    </>
  );
};
