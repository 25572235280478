import { useState } from "react";
import { EmailUrl } from "../constants";

const useSendEmail = () => {
  const [userEmail, setUserEmail] = useState("");

  const addUserEmail = (email) => {
    setUserEmail(email);
  };

  const sendEmail = (language, apiToken) => {
    try {
      fetch(`${EmailUrl}?lng=${language}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: apiToken,
        },
        body: JSON.stringify({ email: userEmail }),
      });
    } catch (error) {}
  };

  return { addUserEmail, sendEmail };
};

export default useSendEmail;
