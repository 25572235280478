import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import "./styles.css";
import { useOpenCv } from "opencv-react";
import { useEffect } from "react";

export const LoadingPage = ({ onBegin, onFinish }) => {
  const { t } = useTranslation("homeModule");
  const { loaded } = useOpenCv();

  useEffect(() => {
    const loadFlowInfo = async () => {
      const res = await onBegin();
      if (res && loaded) {
        const timerId = setTimeout(() => onFinish(), 1000);
        return () => clearTimeout(timerId);
      }
    };

    loadFlowInfo();
  }, [onBegin, onFinish, loaded]);

  return (
    <>
      <div className="home-loading-page">
        <CircularProgress
          disableShrink
          color="inherit"
          size={40}
          thickness={4}
        />
        <p className="loading-text">{t("loading-ai")}...</p>
      </div>
    </>
  );
};
