import QRCode from "react-qr-code";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { trackPageEvent } from "../../services/analyticsService";

export const DesktopPage = () => {
  const { t } = useTranslation("homeModule");

  // Check User is using desktop Device and track this event
  useEffect(() => {
    trackPageEvent("webapp_user_visited_desktop_page");
  }, []);

  return (
    <>
      <div className="desktop-info">
        <h2 className="desktop-title">{t("desktop-title")}</h2>
        <p className="desktop-detail">{t("desktop-detail")}</p>
        <QRCode
          style={{
            height: "40vmin",
            width: "40vmin",
            maxWidth: "200px",
            maxHeight: "200px",
            marginTop: "3rem",
          }}
          bgColor="var(--color-background)"
          value={`${window.location.href}?scanned=true`}
          level="M"
        />
      </div>
      <div className="source-logo">
        <p className="source-text">{t("logo-power")}</p>
        <img src="iqonic-logo.png" alt={"IQONIC.AI"} width={100} />
      </div>
    </>
  );
};
