import Button from "../Button/Button";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { ExclamationMarkIcon } from "../icons/ExclamationMarkIcon";

export const ErrorPage = ({ onResetClick }) => {
  const { t } = useTranslation("errorPage");

  return (
    <div className="error-page">
      <div className="error-text-wrapper">
        <ExclamationMarkIcon className="error-icon" />
        <h4 className="error-page-title">{t("error-title")}</h4>
        <p className="error-page-info">{t("error-info")}</p>
      </div>
      <div className="error-page-button">
        <Button text={t("reset")} onClick={() => onResetClick()} />
      </div>
    </div>
  );
};
