import "./styles.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Button from "../Button/Button";
import { useUID } from "../../context/UIDContext";
import { trackPageEvent } from "../../services/analyticsService";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const Email = ({ updateEmail, updateUserInfo }) => {
  const { t } = useTranslation("questionsModule");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const { uid } = useUID();

  const handleTextInputChange = (event) => {
    setEmail(event.target.value);
    const isEmailValid = emailRegex.test(event.target.value);
    setIsEmailValid(isEmailValid);
  };

  const trackEmailOnContinue = () => {
    trackPageEvent("webapp_user_email_v2", uid, "", email);
  };

  // Check the user comes to email page
  useEffect(() => {
    trackPageEvent("webapp_user_visited_email_page", uid);
  }, [uid]);

  return (
    <>
      <div className="question-module-content full">
        <div className="email-content">
          <div className="email-info-wrapper">
            <h4 className="email-title">E-Mail</h4>
            <p className="email-info">{t("email-detail")}</p>
          </div>
          <div className="email-input-wrapper">
            <input
              type="text"
              placeholder={t("email-placeholder")}
              value={email}
              onChange={handleTextInputChange}
              className="email-input-field"
            />
          </div>
        </div>
      </div>
      <div className="question-module-btn-wrapper">
        <Button
          text={t("continue")}
          onClick={() => {
            updateEmail(email);
            updateUserInfo({ email: email });
            trackEmailOnContinue();
          }}
          disabled={!isEmailValid}
          alternateColor={true}
        />
      </div>
    </>
  );
};
