import { useTranslation } from "react-i18next";
import { useUID } from "../../context/UIDContext";
import { trackPageEvent } from "../../services/analyticsService";
import "./styles.css";

export const ProductCards = ({ products }) => {
  const { t } = useTranslation("recommendation");
  const { uid } = useUID();

  // track product click event
  const trackProductClick = (productTitle, productUrl) => {
    trackPageEvent(
      "webapp_user_clicked_product",
      uid,
      "",
      "",
      productTitle,
      productUrl,
    );
  };

  return (
    <div className="product-cards">
      {products.map((product, index) => (
        <div
          key={index}
          className="product-card"
          onClick={() => {
            trackProductClick(product.title, product.url);
            const newWindow = window.open(
              product.url,
              "_blank",
              "noopener,noreferrer",
            );
            if (newWindow) newWindow.opener = null;
          }}
        >
          <div className="product-img-container">
            <img
              src={`data:image/png;base64, ${product.image}`}
              alt={product.title}
              className="product-img"
            />
          </div>
          <div className="product-text">
            <h4 className="product-title">{product.title}</h4>
            <p className="product-details">{product.detail}</p>
            {product.price && (
              <h4 className="product-price">{product.price}</h4>
            )}
            {product.url && (
              <h4 className="product-link">{t("product-details")}</h4>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
