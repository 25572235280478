import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { CheckBox } from "../CheckBox/CheckBox";
import { useState } from "react";
import { CheckMarkIcon } from "../icons/CheckMarkIcon";
import { BalanceLightIcon } from "../icons/BalanceLightIcon";
import { NoMakeUpIcon } from "../icons/NoMakeUpIcon";
import { NoGlassesIcon } from "../icons/NoGlassesIcon";

export const ValidatePage = ({ onFinish }) => {
  const { t } = useTranslation("homeModule");

  const [isLicenseMarked, setIsLicenseMarked] = useState(false);
  const [isContinuePressed, setIsContinuePressed] = useState(false);

  return (
    <>
      <div className="validate-container">
        <h2 className="validate-title">{t("validate-title")}</h2>
        <p className="validate-sub-title">{t("validate-sub-title")}</p>
        <div className="validate-options-container">
          <div className="validate-option">
            <div
              className={`validate-icon-wrapper ${
                isContinuePressed ? "animate-mark" : ""
              }`}
            >
              {isContinuePressed ? (
                <CheckMarkIcon className="validate-icon" />
              ) : (
                <BalanceLightIcon className="validate-icon" />
              )}
            </div>
            <p className="normal-text">{t("light-text")}</p>
          </div>
          <div className="validate-option">
            <div
              className={`validate-icon-wrapper ${
                isContinuePressed ? "animate-mark" : ""
              }`}
            >
              {isContinuePressed ? (
                <CheckMarkIcon className="validate-icon" />
              ) : (
                <NoGlassesIcon className="validate-icon" />
              )}
            </div>
            <p className="normal-text">{t("eyewear-text")}</p>
          </div>
          <div className="validate-option">
            <div
              className={`validate-icon-wrapper ${
                isContinuePressed ? "animate-mark" : ""
              }`}
            >
              {isContinuePressed ? (
                <CheckMarkIcon className="validate-icon" />
              ) : (
                <NoMakeUpIcon className="validate-icon" />
              )}
            </div>
            <p className="normal-text">{t("makeup-text")}</p>
          </div>
        </div>
      </div>
      {!isContinuePressed && (
        <>
          <div className="info-text terms">
            <div className="license-option">
              <CheckBox
                onClick={() => setIsLicenseMarked((prevValue) => !prevValue)}
              />
              <a
                href="https://www.iqonic.ai/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="normal-text"
              >
                {t("terms-text")}
              </a>
            </div>
          </div>
          <div className="home-button-wrapper">
            <Button
              text={t("continue")}
              onClick={() => {
                setIsContinuePressed(true);
                setTimeout(() => onFinish(), 1000);
              }}
              disabled={!isLicenseMarked}
            />
          </div>
        </>
      )}
    </>
  );
};
