import { useState } from "react";

const useScanImages = () => {
  const [images, setImages] = useState([]);

  const addImage = (faceSide, imageData) => {
    setImages((prevImage) => [...prevImage, { faceSide, imageData }]);
  };

  const uploadImages = async (apiUrl, apiToken) => {
    try {
      for (const image of images) {
        const { faceSide, imageData } = image;
        const imageContent = imageData
          .toString()
          .replace(/^data:image\/jpeg;base64,/, "");
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: apiToken,
          },
          body: JSON.stringify({
            image: imageContent,
            faceSide: faceSide,
          }),
        });
        if (!response.ok) {
          throw new Error("Failed to upload image");
        }
      }
      setImages([]);
      return true;
    } catch (error) {
      return false;
    }
  };

  return { images, addImage, uploadImages };
};

export default useScanImages;
