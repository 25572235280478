import "./styles.css";
import { useRef, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { TabularCard } from "../TabularCard/TabularCard";
import { PercentageCircles } from "../PercentageCircles/PercentageCircles";
import {
  GeneralInfoAi,
  ResultBasedInfoAi,
  TabularInfoAiBar,
} from "./Utilities";
import flowConfig from "../../config/flowConfig.json";
import { useUID } from "../../context/UIDContext";
import { trackPageEvent } from "../../services/analyticsService";

export const Results = ({ results, onProductClick, onExitClick }) => {
  const { t } = useTranslation(["aiResults", "customAiInfo"]);
  const imgRef = useRef(null);
  const [selectedResult, setSelectedResult] = useState(Object.keys(results)[0]);
  const { uid } = useUID();
  const [copyButtonClicked, setCopyButtonClicked] = useState(false);

  useEffect(() => {
    // Track user visits Results Page
    trackPageEvent("webapp_user_visited_results_page", uid, "results");
  }, [uid]);

  const copyResultsToClipboard = () => {
    const resultsString = Object.entries(results)
      .map(([key, { value }]) => `${t(key)} : ${t(value)}`)
      .join(", ");

    navigator.clipboard
      .writeText(resultsString)
      .then(() => {
        setCopyButtonClicked(true);

        setTimeout(() => {
          setCopyButtonClicked(false);
        }, 1000);
      })
      .catch((err) => {
        console.error("Failed to copy text");
      });
  };

  const loadShortInfo = () => {
    switch (selectedResult) {
      case "Hair Gloss":
        return (
          <h4 className="result-decision">
            {results[selectedResult].percentages &&
            Object.keys(results[selectedResult].percentages).length > 0
              ? `${results[selectedResult].percentages.Glossy}% ${t("Glossy")} `
              : t("No hair")}
          </h4>
        );
      case "Product Recommendation":
        return;
      default:
        return (
          <h4 className="result-decision">
            {t(results[selectedResult].value)}
          </h4>
        );
    }
  };

  const loadResultCategories = () => {
    const resultNames = [];
    for (const key in results) {
      resultNames.push(key);
    }

    return resultNames.map((resultName, index) => (
      <button
        className={`result-button ${
          resultName === selectedResult ? "selected" : ""
        }`}
        key={index}
        onClick={() => setSelectedResult(resultName)}
      >
        {t(resultName)}
      </button>
    ));
  };

  return (
    <div className="result">
      <div className="result-short-info">
        <div className="results-image">
          <img
            ref={imgRef}
            className="background-image"
            src={`data:image/png;base64, ${results[selectedResult].image}`}
            alt="Result"
          />
          <div className="top-icons">
            <IoClose className="close-icon" onClick={() => onExitClick()} />
            <button
              className={`copy-button ${copyButtonClicked ? "success" : ""}`}
              onClick={copyResultsToClipboard}
            >
              {copyButtonClicked
                ? t("copy-results-success")
                : t("copy-results")}
            </button>
          </div>
        </div>
        <div className="results-info">
          <p className="result-type">{t(selectedResult)}</p>
          {loadShortInfo()}
          {flowConfig.recommendationEnabled && (
            <button
              onClick={() => onProductClick()}
              className="recommend-button selected"
            >
              {t("Product Recommendation")}
            </button>
          )}
        </div>
        {results[selectedResult].percentages &&
          Object.keys(results[selectedResult].percentages).length > 0 && (
            <PercentageCircles
              percentages={results[selectedResult].percentages}
            />
          )}
        <div className="result-types">
          <div className="result-types-button-wrapper">
            {loadResultCategories()}
          </div>
        </div>
        <div className="text-info-background" />
        <div className="more-info-icon">
          <MdOutlineKeyboardArrowDown className="arrow-down" />
        </div>
      </div>
      <div className="result-detail-info">
        {results[selectedResult].percentages ? (
          <TabularCard tabNames={TabularInfoAiBar[selectedResult]} />
        ) : (
          <p className="result-detail-text">
            {t(
              GeneralInfoAi.includes(selectedResult)
                ? "customAiInfo:" +
                    selectedResult.toLowerCase().split(" ").join("-") +
                    "-info"
                : ResultBasedInfoAi.includes(selectedResult)
                  ? "customAiInfo:" +
                    selectedResult.toLowerCase().split(" ").join("-") +
                    "-" +
                    results[selectedResult].value
                      .toLowerCase()
                      .split(" ")
                      .join("-") +
                    "-info"
                  : "customAiInfo:" +
                    results[selectedResult].value
                      .toLowerCase()
                      .split(" ")
                      .join("-") +
                    "-info",
            )}
          </p>
        )}
      </div>
    </div>
  );
};
