import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import flowConfig from "./config/flowConfig.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    homeModule: require("./locales/en/homeModule.json"),
    scanModule: require("./locales/en/scanModule.json"),
    questionsModule: require("./locales/en/questionsModule.json"),
    aiResults: require("./locales/en/aiResults.json"),
    recommendation: require("./locales/en/recommendation.json"),
    customAiInfo: require("./locales/en/customAiInfo.json"),
    customHomeInfo: require("./locales/en/customHomeInfo.json"),
    customQuestions: require("./locales/en/customQuestions.json"),
    errorPage: require("./locales/en/errorPage.json"),
  },
  de: {
    homeModule: require("./locales/de/homeModule.json"),
    scanModule: require("./locales/de/scanModule.json"),
    questionsModule: require("./locales/de/questionsModule.json"),
    aiResults: require("./locales/de/aiResults.json"),
    recommendation: require("./locales/de/recommendation.json"),
    customAiInfo: require("./locales/de/customAiInfo.json"),
    customHomeInfo: require("./locales/de/customHomeInfo.json"),
    customQuestions: require("./locales/de/customQuestions.json"),
    errorPage: require("./locales/de/errorPage.json"),
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: flowConfig.defaultLanguage,
    supportedLngs: flowConfig.availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
