import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";
import flowConfig from "../../config/flowConfig.json";
import { isDesktop } from "react-device-detect";
import { DesktopPage } from "./DesktopPage";
import { ScifiWireAnimation } from "../animations/ScifiWireAnimation/ScifiWireAnimation";
import { useEffect } from "react";
import { generateUUID } from "../../services/uidService";
import { trackPageEvent } from "../../services/analyticsService";
import { useUID } from "../../context/UIDContext";
import { useLocation } from "react-router-dom";

export const WelcomePage = ({ onFinish }) => {
  const { t } = useTranslation("customHomeInfo");
  const { updateUID } = useUID();
  const location = useLocation();

  // Check the user didn't come from scanning desktop device
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isScanned = params.get("scanned");
    const uid = generateUUID();

    // Check User is using mobile Device and track this event
    if (!isScanned && !isDesktop) {
      updateUID(uid);
      trackPageEvent("webapp_user_visited_home", uid);
    }
  }, [location.search, updateUID]);

  return (
    <>
      {isDesktop ? (
        <DesktopPage />
      ) : (
        <>
          <ScifiWireAnimation targetRadius={200} />
          <div className="customer-logo-container">
            <img
              src="logo.png"
              alt="Logo"
              width="320"
              style={{
                width: "40vmin",
                maxWidth: "200px",
              }}
            />
            {flowConfig.availableLanguages.length > 1 && (
              <div className="language-selector">
                <LanguageSelector />
              </div>
            )}
          </div>
          <div className="info-text">
            <h2 className="title-text">{t("custom-home-title")}</h2>
            <p className="home-sub-title">{t("custom-home-subtitle")}</p>
          </div>
          <div className="home-button-wrapper">
            <Button text={t("custom-home-button")} onClick={() => onFinish()} />
          </div>
        </>
      )}
    </>
  );
};
