import { useState } from "react";
import { Email } from "../Email/Email";
import "./styles.css";
import { Form } from "../Form/Form";
import { useTranslation } from "react-i18next";

export const InfoCollection = ({
  emailEnabled,
  updateEmail,
  questionsData,
  onComplete,
}) => {
  const { i18n } = useTranslation();
  const [infoStep, setInfoStep] = useState(emailEnabled ? 0 : 1);
  const [userInfo, setUserInfo] = useState({});

  const loadNextStep = (data) => {
    if (questionsData.length < 1 || infoStep === 1) {
      onComplete({ ...userInfo, ...data, lang: i18n.language });
    } else {
      setUserInfo((prevData) => ({ ...prevData, ...data }));
      setInfoStep((prevValue) => prevValue + 1);
    }
  };

  return (
    <>
      {infoStep === 0 ? (
        <Email updateEmail={updateEmail} updateUserInfo={loadNextStep} />
      ) : (
        <Form formData={questionsData} onComplete={loadNextStep} />
      )}
    </>
  );
};
