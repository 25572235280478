export const GeneralInfoAi = [
  "Skin Age",
  "Puffy Eyes", // TODO: Move this to ResultBasedInfoAi
  "Hair Color",
  "Hair Texture",
  "Hair Color v2",
  "Hair Length",
  "Receding Hair Line",
  "Eyebrow Density",
  "Lip Natural Color",
];

export const ResultBasedInfoAi = [
  "Hair Texture v2",
  "Hair Volume",
  "Lip Condition",
];

export const TabularInfoAiBar = {
  "Skin Type": ["Oily", "Dry", "Normal", "Combined"],
  "Hair Gloss": ["Glossy", "Dull"],
};
