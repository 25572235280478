import { useState } from "react";
import { CheckMarkIcon } from "../icons/CheckMarkIcon";
import "./styles.css";

export const CheckBox = ({ initialState, onClick }) => {
  const [checked, setChecked] = useState(initialState || false);

  const handleClick = (event) => {
    setChecked((prevValue) => !prevValue);
    if (onClick) onClick();
  };

  return (
    <div className="checkbox" onClick={handleClick}>
      {checked && <CheckMarkIcon className="checkmark" thickness={"2.5"} />}
    </div>
  );
};
